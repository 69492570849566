// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  PINATA_KEY: '3816585697981d38d50d',
  PINATA_SECRET: '4722d4967331aafb013d9dab2bc6e3534e654be9a1cbc8ce4aa4adaec3c13f27',
  BASE_URL: 'https://chocolate-magnificent-blackbird-410.mypinata.cloud/ipfs/',
  API_BASE_URL: 'https://preapi.realworld.fi/api/v1',
  API_BASE_URL_V2: 'https://preapi.realworld.fi/api/v2',
  SUPPORTED_CHAINS: ['137'],
  CHAIN_ID: '0x89',
  WEB_SITE_URL: 'https://devapp.realworld.fi',
  WALLET_CONNECT_PROJECT_ID: "b6c201630414d0fd586cfc6add82cde7",
  BUG_ATLAS_API_KEY: 'CIEW9TJB9KBZE83I',
  BUG_ATLAS_SECRET_KEY: 'HEIZT5FYN5SER86Z',

  '137': {
    TRANSH_URL: 'https://polygonscan.com/tx/',
    EXPLORER_URL: 'https://polygonscan.com/',
    FACTORY_ADDRESS: '0x64E003Be13d6e46b910bb9294b444f6fd4Ad2DcB',
    ACCESS_CONTROL_TOKEN: '0xCfC3EaC17FcbdcA8DDAc02A930A62b5eb2e47099',
    PROVIDER: 'https://polygon-mainnet.infura.io/v3/dd42eb8e92fb4f55b21dc1070bb1d997',
    CHAIN_NETWORK: '0x89',
    CHAINID: 137,
    CURRENCY_NAME: 'MATIC',
    CURRENCY_SYMBOL: 'MATIC',
    CHAIN_NAME: 'Polygon',
    FILE_PATH: '',
    BORROW_LEND_CONTRACT: '0x155Fbb45b939D49686A3823CE460Ba8d33380b6A',
    EXCHANGE_CONTRACT: '0x7ae493eAD99Ae98EACF8f108D8070cE473357012',
    LAZYMINT_CONTRACT: '0xeb32216a04474cd6c126904f19f875cc35914b28',
    ERC721_CONTRACT: '0xAFc9942f237ea8B21CE8b506FcB95A84eC3D03e8',
    PLATFORM_FEE: "0x8C67c7dd650fD34f5A69C2084c6be6B8F2d9d60b"
  },
  // Idle
  AUTO_LOGOUT_DURATION: 21600, //(six hours) In seconds; how long for inactive modal to appear
  AUTO_LOGOUT_TIMEOUT: 30, // In seconds; how long for user to act after inactive modal appears before being logged out.
};
